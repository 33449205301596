import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./index.scss";

interface BrandsProps {
  settings?: any;
  light?: any;
}

const Brands: React.FC<BrandsProps> = ({ settings, light = false }) => {
  return (
    <section className={light ? "brands light" : "brands"}>
      <div className="container">
        <h3>{settings.subTitle}</h3>
        <h2>{settings.title}</h2>
        <p className="brands-desc">{settings.content}</p>
        <div className="brands-wrap">
          {settings?.listAwards.map((e: any, i: any) => {
            const image = getImage(e?.image);
            if (!image) return null;
            return (
              <div className="brands-item" key={i}>
                <a href={e?.slug} target="_blank">
                  <GatsbyImage image={image} alt={e?.name} />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Brands;
