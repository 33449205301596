import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./index.scss";

interface HeroHighType {
  settings?: any;
}

const HeroHigh: React.FC<HeroHighType> = ({ settings }) => {
  const image = getImage(settings?.images[0]?.heroImage);
  return (
    <section className="services-high">
      <div className="container get-listed">
        <h2>{settings?.title}</h2>
        <p className="services-high-desc">{settings?.subTitle}</p>
        <div className="services-high-button">
          <Link to={settings?.buttonLink}>
            <button type="button" className="orangeButton">
              {settings?.buttonContent}
            </button>
          </Link>
        </div>
        <div className="bg-cover"></div>
        <GatsbyImage image={image} alt="" className="background-hero" />
      </div>
    </section>
  );
};

export default HeroHigh;
